export const CATEGORY_TYPE = {
  ALL: 'All',
}

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
}

export const THEME_2 = {
  NONE: 'none',
  PARTICLE: 'particle',
}
